import { Box, useTheme } from '@mui/material'

const getStyles = (theme) => ({
  svg: {
    width: 1,
    '& .front': {
      fill: theme.overrides.background.intro.transitionFront,
    },
    '& .middle': {
      fill: theme.overrides.background.intro.transitionMiddle,
    },
    '& .back': {
      fill: theme.overrides.background.intro.transitionBack,
    },
  },
})

function TransitionSvg() {
  const theme = useTheme()
  const styles = getStyles(theme)
  return (
    <Box
      sx={styles.svg}
      component='svg'
      viewBox='0 0 900 300'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.1'
    >
      <path
        className='back'
        d='M0 135L13.7 130.8C27.3 126.7 54.7 118.3 82 127.7C109.3 137 136.7 164 163.8 176.2C191 188.3 218 185.7 245.2 171.5C272.3 157.3 299.7 131.7 327 123.3C354.3 115 381.7 124 409 112.2C436.3 100.3 463.7 67.7 491 53.2C518.3 38.7 545.7 42.3 573 59.2C600.3 76 627.7 106 654.8 136.2C682 166.3 709 196.7 736.2 191.3C763.3 186 790.7 145 818 112.5C845.3 80 872.7 56 886.3 44L900 32L900 301L886.3 301C872.7 301 845.3 301 818 301C790.7 301 763.3 301 736.2 301C709 301 682 301 654.8 301C627.7 301 600.3 301 573 301C545.7 301 518.3 301 491 301C463.7 301 436.3 301 409 301C381.7 301 354.3 301 327 301C299.7 301 272.3 301 245.2 301C218 301 191 301 163.8 301C136.7 301 109.3 301 82 301C54.7 301 27.3 301 13.7 301L0 301Z'
      ></path>
      <path
        className='middle'
        d='M0 156L13.7 173.8C27.3 191.7 54.7 227.3 82 241.8C109.3 256.3 136.7 249.7 163.8 228.3C191 207 218 171 245.2 167.7C272.3 164.3 299.7 193.7 327 193.5C354.3 193.3 381.7 163.7 409 158.3C436.3 153 463.7 172 491 187.3C518.3 202.7 545.7 214.3 573 214C600.3 213.7 627.7 201.3 654.8 189.8C682 178.3 709 167.7 736.2 161.8C763.3 156 790.7 155 818 149.5C845.3 144 872.7 134 886.3 129L900 124L900 301L886.3 301C872.7 301 845.3 301 818 301C790.7 301 763.3 301 736.2 301C709 301 682 301 654.8 301C627.7 301 600.3 301 573 301C545.7 301 518.3 301 491 301C463.7 301 436.3 301 409 301C381.7 301 354.3 301 327 301C299.7 301 272.3 301 245.2 301C218 301 191 301 163.8 301C136.7 301 109.3 301 82 301C54.7 301 27.3 301 13.7 301L0 301Z'
      ></path>
      <path
        className='front'
        d='M0 260L13.7 262.3C27.3 264.7 54.7 269.3 82 259.7C109.3 250 136.7 226 163.8 214.3C191 202.7 218 203.3 245.2 214.2C272.3 225 299.7 246 327 250.8C354.3 255.7 381.7 244.3 409 229.5C436.3 214.7 463.7 196.3 491 193.8C518.3 191.3 545.7 204.7 573 221C600.3 237.3 627.7 256.7 654.8 248.3C682 240 709 204 736.2 206C763.3 208 790.7 248 818 254.7C845.3 261.3 872.7 234.7 886.3 221.3L900 208L900 301L886.3 301C872.7 301 845.3 301 818 301C790.7 301 763.3 301 736.2 301C709 301 682 301 654.8 301C627.7 301 600.3 301 573 301C545.7 301 518.3 301 491 301C463.7 301 436.3 301 409 301C381.7 301 354.3 301 327 301C299.7 301 272.3 301 245.2 301C218 301 191 301 163.8 301C136.7 301 109.3 301 82 301C54.7 301 27.3 301 13.7 301L0 301Z'
      ></path>
    </Box>
  )
}

export default TransitionSvg
